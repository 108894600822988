<template>
  <div>
    <TeamTitle :title="$t('team.joinUs')" />
    <div
      v-html="fieldIntroductionJoinUs"
      class="pb-10 lg:pb-14 border-b border-grisClair font-raleway text-regularMobile lg:text-regular"
    />
    <div class="my-14 flex gap-4 flex-col">
      <p
        v-if="fieldRelatedJobs?.length > 0"
        class="font-raleway font-normal leading-[130%] text-sm"
      >
        {{ fieldRelatedJobs?.length }} {{  $t("job.jobOffer") }}
      </p>
      <p v-else class="font-raleway font-normal leading-[130%] text-sm">
        {{ $t('job_offer.no_offer') }}
      </p>
      <div v-if="fieldRelatedJobs?.length > 0" class="flex flex-col border-t border-grisClair">
        <div
            v-for="job in fieldRelatedJobs"
            :key="job.id"
            class="flex flex-row-reverse justify-end lg:justify-normal gap-4 lg:gap-6 lg:flex-row py-6 border-b border-b-grisClair hover:border-orangeCurie">
          <div class="flex flex-col gap-2.5 overflow-hidden">
            <div class="flex gap-2.5">
              <div
                  class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-small py-1 px-1.5">
                {{
                  job.fieldJobContract.name
                }}
              </div>
              <div class="flex items-center font-raleway text-small">
                <nuxt-icon name="map-alt" filled class="mr-1 text-regular flex items-center"/>
                {{
                  job.fieldJobLocation.name
                }}
              </div>
            </div>
            <UiCustomLink
            :path="job.path.alias">
              <h3 class="m-0 font font-raleway hover:text-orangeCurie font-bold text-h3Small lg:text-medium">{{ job.fieldTitle }}</h3>
            </UiCustomLink>
            <p class="font-raleway text-regularMobile lg:text-regular leading-[25px]">{{ formatDate(job.fieldDateEnd2.value) }}</p>
            <ul v-if="job.fieldJobSector?.name" class="font-raleway lg:text-regular overflow-x-scroll lg:overflow-x-hidden flex gap-2 lg:flex-wrap">
              <li class="border border-grisClair rounded-[100px] px-3 py-1 w-fit text-small">{{ job.fieldJobSector.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <UiButton
          v-if="backLink"
          :path="`${backLink}`"
          :buttonLabel="$t('job.seeMore')"
          size="medium"
          variant="secondary"
          class="w-full text-center lg:w-fit mt-4"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { FieldRelatedJob } from "~/interfaces/team";

import { formatDate } from "~/utils/dateUtils";
import { useBackLink } from "~/composables/backlink";
const { getBackLink } = useBackLink();
const backLink = getBackLink("job_offer_list");

const props = defineProps({
  fieldIntroductionJoinUs: String,
  fieldRelatedJobs: Array as PropType<FieldRelatedJob[]>,
});

const formatDateWithSlashes = (date: string) => {
  return date.replace(/-/g, "/");
};
</script>
